<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12 col-md-8 col-lg-8 col-xl-6 mx-auto">
        <Form type="view" />
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/Page-header";
import Form from "@/views/pages/roles/form";

export default {
  metaInfo() {
    return {
      title: this.$appConfig.title + " | " + this.$t("role.info"),
    };
  },
  components: {
    Layout,
    PageHeader,
    Form,
  },
  data() {
    return {
      title: "role.info",
      items: [
        {
          text: this.$appConfig.title,
          to: "/",
        },
        {
          tran: "sidebar.roles",
          to: "/roles",
        },
        {
          tran: "role.info",
          active: true,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep
  .permissions-table
  .custom-control-input[disabled]
  + .custom-control-label {
  cursor: default;
  opacity: .7;
}
</style>
